import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { log, revokeAccessToken } from '@api/ccc-api-calls'
import logoSvg from '@assets/logo.png'
import { selectors as transactionSelector } from '@modules/transaction'
import { selectors as authSelectors } from '@modules/auth'
import ErrorToast from '@components/error-toast'
import { getRouteByPath, routeCanCancel, routeCanGoBack, routes, wizards } from '../../routes'
import GrvIcon from '../grv-icon'
import BackButton from './back-button'

/* eslint-disable no-console */
const PageHeader = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const errors = useSelector(state => state.error.errors)
  const cancelConfirmationContainerRef = useRef()
  const [isCancelConfirmationOpen, setCancelConfirmationOpen] = useState(false)
  const clientCorrelationId = useSelector(transactionSelector.clientCorrelationId)
  const kioskName = useSelector(transactionSelector.kioskName)
  const route = getRouteByPath(location.pathname)
  const [routeBackObj, canGoBack] = routeCanGoBack(wizards.buyCashiersCheck, route)
  const [canCancel] = routeCanCancel(wizards.buyCashiersCheck, route)
  const accessToken = useSelector(authSelectors.accessToken)
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)

  useEffect(() => {
    if (cancelConfirmationContainerRef.current) {
      if (isCancelConfirmationOpen)
        cancelConfirmationContainerRef.current.style.height = `${cancelConfirmationContainerRef.current.scrollHeight}px`
      else {
        cancelConfirmationContainerRef.current.style.removeProperty('height')
      }
    }
  }, [cancelConfirmationContainerRef, isCancelConfirmationOpen])

  if (routes.landing.path === location.pathname) {
    return (
      <div className='landing-header-container'>
        <div className='landing-header-logo-container'>
          <img src={logoSvg} alt={t('common:capitalOneLogoAltText')} />
        </div>
      </div>
    )
  }

  if (
    [routes.pollPrintStatus.path, routes.error.path, routes.sessionTimeout.path].includes(
      location.pathname
    )
  ) {
    return <div />
  }

  async function cancelTransaction() {
    try {
      const endpoint = `${process.env.REACT_APP_EXCHANGE_CCC_HOST_OL_ENDPOINT}/cancel-transaction`
      const res = await fetch(endpoint, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'client-correlation-id': clientCorrelationId,
          'kiosk-name': kioskName,
          Accept: 'application/json;v=3',
          'Content-Type': 'application/json;v=3',
          Authorization: `Bearer ${accessToken}`
        }
      })
      if (res.status !== 200) {
        if (res.status === 403) {
          navigate('/session-timeout')
        } else {
          throw new Error('Not 200 OK')
        }
      }

      await res.json()
    } catch (err) {
      if (isAuthenticated) {
        log(kioskName, clientCorrelationId, err.toString(), accessToken)
      } else {
        console.log(err.toString())
      }
    } finally {
      if (isAuthenticated) {
        revokeAccessToken(accessToken)
      }
    }
  }

  return (
    <div className='wizard-header-container'>
      <div className='action-bar-container'>
        <div>{canGoBack && <BackButton {...routeBackObj} />}</div>
        <div>
          {canCancel && (
            <div
              className='action-bar-item'
              id='open-cancel-menu'
              role='button'
              aria-label='Cancel transaction'
              tabIndex={0}
              onClick={() => setCancelConfirmationOpen(open => !open)}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  setCancelConfirmationOpen(open => !open)
                }
              }}
            >
              <GrvIcon className='action-bar-item-icon' iconClass='ui-lined-close-1-24' />
            </div>
          )}
        </div>
      </div>
      <div
        ref={cancelConfirmationContainerRef}
        className='cancel-confirmation-container'
        aria-hidden={!isCancelConfirmationOpen}
      >
        <div className='cancel-confirmation-prompt'>
          Are you sure you want to discard your transaction and log out?
        </div>
        <div className='cancel-buttons-container'>
          <span
            className='cancel-confirmation-button'
            role='button'
            tabIndex={isCancelConfirmationOpen ? 0 : null}
            onClick={() => {
              cancelTransaction().then(() =>
                window.location.replace(process.env.REACT_APP_ACKNOWLEDGMENT_REDIRECT)
              )
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                cancelTransaction().then(() =>
                  window.location.replace(process.env.REACT_APP_ACKNOWLEDGMENT_REDIRECT)
                )
              }
            }}
          >
            <GrvIcon iconClass='ui-lined-' />
            Yes, cancel
          </span>
          <span
            className='cancel-confirmation-button'
            role='button'
            tabIndex={isCancelConfirmationOpen ? 0 : null}
            onClick={() => setCancelConfirmationOpen(open => !open)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                setCancelConfirmationOpen(open => !open)
              }
            }}
          >
            <GrvIcon iconClass='ui-lined' />
            No, stay
          </span>
        </div>
      </div>

      {Object.keys(errors).length > 0 && <ErrorToast />}

      <h1 className='wizard-page-title'>{t('common:appHeader')}</h1>
    </div>
  )
}

export default PageHeader
