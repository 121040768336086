import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'

import { selectors as transactionSelectors } from '@modules/transaction'
import { selectors as authSelectors } from '@modules/auth'
import { log } from '@api/ccc-api-calls'

const ProtectedRoute = () => {
  const kioskName = useSelector(transactionSelectors.kioskName)
  const clientCorrelationId = useSelector(transactionSelectors.clientCorrelationId)
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const accessToken = useSelector(authSelectors.accessToken)
  const location = useLocation()
  /* eslint-disable no-console */
  log(
    kioskName,
    clientCorrelationId,
    `Accessing protected route: ${location.pathname}`,
    accessToken
  )
  const params = {
    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_OAUTH_AUTHORIZATION_CODE_HANDLER_ENDPOINT,
    grant_type: 'authorization_code',
    scope: 'openid',
    'response-type': 'code',
    state: JSON.stringify({
      'redirect-location-after-auth': '/account-selection',
      'kiosk-name': kioskName,
      'client-correlation-id': clientCorrelationId
    })
  }

  if (isAuthenticated) {
    return <Outlet />
  }

  const endpoint = process.env.REACT_APP_AUTH_ENDPOINT
  const authFlowUrl = `${endpoint}?${new URLSearchParams(params).toString()}`
  console.log(`Customer is not authenticated, redirecting to authFlow: ${authFlowUrl}`)
  window.location.href = authFlowUrl
  return 'Redirecting to secure sign-in'
}

export default ProtectedRoute
