import React, { Suspense } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import App from '@components/app'
import Loader from '@components/loader'

import '@src/i18n'
import configureStore from '@src/store'
import '@sass/main.scss'

const target = document.querySelector('#root')

const { store, persistor } = configureStore()

render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </PersistGate>
  </Provider>,
  target
)
