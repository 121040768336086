import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { log, revokeAccessToken } from '@api/ccc-api-calls'
import {
  actions as transactionActions,
  selectors as transactionSelectors
} from '@modules/transaction'
import { actions as errorActions } from '@modules/error'
import { selectors as authSelectors } from '@modules/auth'
import { useActions } from '@hooks/use-actions'
import { GLOBAL_ERROR } from '@util/error'
import Loader from '@components/loader'
import { routes, wizards } from '../../routes'
import PageHeader from './page-header'
import ProtectedRoute from '../protected-route'

/* eslint-disable no-console */
const App = () => {
  const accessToken = useSelector(authSelectors.accessToken)
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const location = useLocation()
  const navigate = useNavigate()
  const [locationArray, setLocationArray] = useState([])

  const requireKioskNameError = useMemo(() => {
    return {
      'kiosk-name':
        'The app requires a kiosk-name be set in the url search params (i.e. localhost:3000/?kiosk-name=Test+Kiosk+1)'
    }
  }, [])
  const requireSaltError = useMemo(() => {
    return {
      salt: 'The app requires a salt to be set in the url search params (i.e. localhost:3000/?salt=abcd1234)'
    }
  }, [])
  const [
    setGeneralError,
    clearErrors,
    fetchClientCorrelationId,
    fetchClientCorrelationIdOk,
    setKioskName,
    setSalt
  ] = useActions([
    errorActions.setGeneralError,
    errorActions.clearErrors,
    transactionActions.fetchClientCorrelationId,
    transactionActions.fetchClientCorrelationIdOk,
    transactionActions.setKioskName,
    transactionActions.setSalt
  ])
  const searchParams = new URLSearchParams(location.search)
  const stateParam = searchParams.get('state') || ''
  let state
  try {
    state = JSON.parse(decodeURIComponent(stateParam)) || {}
  } catch (e) {
    state = {}
  }
  const kioskName =
    useSelector(transactionSelectors.kioskName) ||
    decodeURIComponent(searchParams.get('kiosk-name') || '') ||
    state['kiosk-name'] ||
    ''
  const clientCorrelationId =
    useSelector(transactionSelectors.clientCorrelationId) || state['client-correlation-id'] || ''

  useEffect(() => {
    // logging current page of app when authenticated
    log(kioskName, clientCorrelationId, `User is now on page ${location.pathname}`, accessToken)
  }, [location])

  const salt =
    useSelector(transactionSelectors.salt) ||
    decodeURIComponent(searchParams.get('salt') || '') ||
    state.salt ||
    ''

  useEffect(() => {
    if (salt) setSalt(salt)
    else setGeneralError({ urlSearchParams: requireSaltError })
  }, [salt, setSalt])

  useEffect(() => {
    function redirectToError(e) {
      navigate('/error', {
        state: {
          errorTitle: e.detail.error.title,
          errorDetail: e.detail.error.detail
        },
        kioskName
      })
    }
    document.addEventListener(
      GLOBAL_ERROR,
      e => {
        redirectToError(e)
      },
      false
    )
    return () => {
      document.removeEventListener(GLOBAL_ERROR, e => {
        redirectToError(e)
      })
    }
  }, [navigate, kioskName, salt])

  useEffect(() => {
    if (!location.pathname.includes('/login') && !location.pathname.includes('/oauth2')) {
      setLocationArray(locationArray => [...locationArray, location])
    }
    clearErrors()
  }, [location, clearErrors])

  useEffect(() => {
    if (kioskName) setKioskName(kioskName)
    else setGeneralError({ urlSearchParams: requireKioskNameError })
    if (clientCorrelationId) fetchClientCorrelationIdOk(clientCorrelationId)
    else if (kioskName && !clientCorrelationId) fetchClientCorrelationId()
  }, [
    kioskName,
    setKioskName,
    clientCorrelationId,
    fetchClientCorrelationId,
    fetchClientCorrelationIdOk,
    setGeneralError,
    requireKioskNameError
  ])

  async function cancelTransaction() {
    try {
      const endpoint = `${process.env.REACT_APP_EXCHANGE_CCC_HOST_OL_ENDPOINT}/cancel-transaction`
      const res = await fetch(endpoint, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'client-correlation-id': clientCorrelationId,
          'kiosk-name': kioskName,
          Accept: 'application/json;v=3',
          'Content-Type': 'application/json;v=3',
          Authorization: `Bearer ${accessToken}`
        }
      })
      if (res.status !== 200) {
        if (res.status === 403) {
          navigate('/session-timeout')
        } else {
          throw new Error('Not 200 OK')
        }
      }

      await res.json()
    } catch (err) {
      if (isAuthenticated) {
        log(kioskName, clientCorrelationId, err.toString(), accessToken)
      } else {
        console.log(err.toString())
      }
    } finally {
      if (isAuthenticated) {
        revokeAccessToken(accessToken)
      }
    }
  }

  window.onpopstate = function () {
    const confirm = window.confirm(
      'Are you sure you want to leave the page? Your transaction will be lost.'
    )

    if (confirm) {
      cancelTransaction().then(() => {
        window.location.replace(process.env.REACT_APP_ACKNOWLEDGMENT_REDIRECT)
      })
    } else {
      navigate(locationArray[locationArray.length - 2])
    }
  }

  return (
    <>
      {
        // CCID always undefined on create transaction error resulting in
        // indefinite loader on error page.
        !location.pathname.includes('/error') && (!clientCorrelationId || !kioskName) ? (
          <Loader />
        ) : (
          <>
            <PageHeader />
            <Routes style={{ height: '100%' }}>
              <Route {...routes.login} />
              <Route {...routes.token} />
              {wizards.buyCashiersCheck.routes.map(route => {
                return route.protected ? (
                  <Route element={<ProtectedRoute />} key={route.path}>
                    <Route key={route.path} {...route} />
                  </Route>
                ) : (
                  <Route key={route.path} {...route} />
                )
              })}
              <Route {...routes.sessionTimeout} />
            </Routes>
          </>
        )
      }
    </>
  )
}

export default App
