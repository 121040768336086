import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { reinit as storeReinit } from '@src/store'
import { useActions } from '@hooks/use-actions'

import disputeIcon from '@assets/Dispute.svg'
import noticeIcon from '@assets/are-you-sure.svg'
import snagIcon from '@assets/snag.png'
import bellIcon from '@assets/notification.svg'
import roadBlockIcon from '@assets/road-block.svg'

import { log, revokeAccessToken } from '@api/ccc-api-calls'
import { selectors as authSelectors } from '@modules/auth'
import { selectors as transactionSelector } from '@modules/transaction'
import { formatAmountToCurrency, formatCentsToDollarsAndCents } from '@util/currency'

/* eslint-disable no-console */
const Error = function () {
  const location = useLocation()
  console.log('location', location)
  const { t } = useTranslation()
  const reinit = useActions(storeReinit)
  const customerCheckCentsLimit = useSelector(transactionSelector.customerCheckCentsLimit)

  let errorIcon
  let errorTitle
  let errorMessage
  let errorMessage2
  const errorKey = location.state && location.state.errorTitle
  const errorDetail = location.state && location.state.errorDetail
  const clientCorrelationId = useSelector(transactionSelector.clientCorrelationId)
  const kioskName = useSelector(transactionSelector.kioskName)
  const accessToken = useSelector(authSelectors.accessToken)
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)

  if (isAuthenticated) {
    log(
      kioskName,
      clientCorrelationId,
      `errorKey: ${errorKey}, errorDetail: ${errorDetail}`,
      accessToken
    )
    revokeAccessToken(accessToken)
  } else {
    console.log(`errorKey: ${errorKey}, errorDetail: ${errorDetail}`)
  }

  switch (errorKey) {
    case 'ExceededCheckCountCustomerLimit':
      errorIcon = bellIcon
      errorTitle = t('error.customerMaxCheckCount.title')
      errorMessage = t('error.customerMaxCheckCount.message')
      break
    case 'ExceededCheckValueCustomerLimit':
      errorIcon = bellIcon
      errorTitle = t('error.customerMaxCheckValue.title')
      errorMessage = `${t('error.customerMaxCheckValue.message_1_part_1')} ${formatAmountToCurrency(
        formatCentsToDollarsAndCents(customerCheckCentsLimit)
      )} ${t('error.customerMaxCheckValue.message_1_part_2')}`
      errorMessage2 = `${t('error.customerMaxCheckValue.message_2')}`
      break
    case 'AccountError':
      errorIcon = disputeIcon
      errorTitle = t('error.accountError.title')
      errorMessage = t('error.accountError.message')
      break
    case 'SaltValidationFailure':
      errorIcon = noticeIcon
      errorTitle = t('error.saltValidationFailure.title')
      errorMessage = t('error.saltValidationFailure.message')
      break
    case 'CheckNotTakenError':
      errorIcon = noticeIcon
      errorTitle = t('error.checkRetraction.title')
      errorMessage = t('error.checkRetraction.message_1')
      errorMessage2 = t('error.checkRetraction.message_2')
      break
    case 'PrinterOrPresenterError':
      errorIcon = roadBlockIcon
      errorTitle = t('error.printerError.title')
      errorMessage = t('error.printerError.message_1')
      errorMessage2 = t('error.printerError.message_2')
      break
    case 'ReversalError':
      errorIcon = roadBlockIcon
      errorTitle = t('error.reversalError.title')
      errorMessage = t('error.reversalError.message_1')
      errorMessage2 = t('error.reversalError.message_2')
      break
    default:
      errorIcon = snagIcon
      errorTitle = t('error.general.title')
      errorMessage = t('error.general.message')
  }

  return (
    <div className='content-box'>
      <div style={{ textAlign: 'center' }}>
        <img
          style={{ width: '75px', height: '75px', marginBottom: '20px' }}
          src={errorIcon}
          alt=''
        />
        <h1 id='error-title' className='wizard-page-title'>
          {errorTitle}
        </h1>
        <p id='error-message1' style={{ marginBottom: 30 }} className='grv-text__body--small'>
          {errorMessage}
        </p>
        {errorMessage2 && (
          <p id='error-message2' style={{ marginBottom: 40 }} className='grv-text__body--small'>
            {errorMessage2}
          </p>
        )}
        <button
          type='button'
          tabIndex={0}
          className='progress-button'
          style={{ width: '100%', marginTop: '20px' }}
          onClick={() => {
            reinit()
            window.location.replace(process.env.REACT_APP_ACKNOWLEDGMENT_REDIRECT)
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              reinit()
              window.location.replace(process.env.REACT_APP_ACKNOWLEDGMENT_REDIRECT)
            }
          }}
        >
          OK
        </button>
      </div>
    </div>
  )
}

export default Error
