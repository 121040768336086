import { put, select, takeLatest } from 'redux-saga/effects'
import { log } from '@api/ccc-api-calls'
import { defaultReducer } from '@util/default-reducer'
import { observeStore } from '@util/observe-store'
import { actionMatcher } from '@util/saga-action-matcher'
import { handleErrorDispatch } from '@util/error'
import { handleApiErrors } from '@util/api-error-detect'
/* eslint-disable-next-line import/no-cycle */
import { selectors as transactionSelectors } from './transaction'
import { actions as errorActions } from './error'

const initialState = {
  authorizationCode: '',
  accessToken: '',
  refreshToken: '',
  profileReferenceId: ''
}

export const selectors = {
  accessToken: state => state.auth.accessToken,
  profileReferenceId: state => state.auth.profileReferenceId,
  isAuthenticated: state => state.auth.accessToken
}

export const sagas = {
  *fetchOauthAccessToken({ saga: { next, reject } }) {
    const authorizationCode = yield select(s => s.auth.authorizationCode)
    const clientCorrelationId = yield select(transactionSelectors.clientCorrelationId)
    const kioskName = yield select(transactionSelectors.kioskName)
    /* eslint-disable no-console */
    try {
      const response = yield fetch(
        `${process.env.REACT_APP_CCC_HOST_OL_ENDPOINT}/access-token?${new URLSearchParams({
          authorizationCode
        })}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'kiosk-name': kioskName,
            'client-correlation-id': clientCorrelationId,
            Accept: 'application/json;v=3',
            'Content-Type': 'application/json;v=3'
          }
        }
      ).then(handleApiErrors)
      yield put(next(response.accessToken, response.refreshToken))
    } catch (e) {
      console.log(e.toString())
      if (e.statusCode >= 400 && e.statusCode <= 499) {
        yield put(errorActions.setGeneralError(e))
        yield put(reject(e))
      } else {
        yield put(errorActions.setGeneralError(e))
        handleErrorDispatch(e.name, e.message)
      }
    }
  },
  *loginThenGotoTokenComponent({ saga: { next, reject } }) {
    const kioskName = yield select(transactionSelectors.kioskName)
    const clientCorrelationId = yield select(transactionSelectors.clientCorrelationId)
    const data = {
      state: JSON.stringify({
        'redirect-location-after-auth': '/account-selection',
        'kiosk-name': kioskName,
        'client-correlation-id': clientCorrelationId
      })
    }
    // TODO log the action with a correlation id and the arguments supplied?
    // TODO add the action to the loading map
    // TODO send action to the audit trail
    /* put(logActionCreators.log('correlation id and other stuff')) */
    try {
      const response = yield fetch(
        `${process.env.REACT_APP_CCC_PRIVATE_OL_ENDPOINT}/profile-reference-id`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'kiosk-name': kioskName,
            'client-correlation-id': clientCorrelationId,
            Accept: 'application/json;v=2',
            'Content-Type': 'application/json;v=2'
          }
        }
      ).then(handleApiErrors)
      console.log(`offered login with response body: ${JSON.stringify(response)}`)
      if (response.profileReferenceId) {
        data.code = response.profileReferenceId
        yield put(next(response.profileReferenceId))
        yield put(errorActions.clearErrors())
        window.location.replace(`/oauth2/token?${new URLSearchParams(data).toString()}`)
      } else {
        throw new Error(response)
      }
    } catch (e) {
      console.log(e.toString())
      if (e.statusCode >= 400 && e.statusCode <= 499) {
        yield put(errorActions.setGeneralError(e))
        yield put(reject(e))
      } else {
        yield put(errorActions.setGeneralError(e))
      }
    }
  },
  *logout({ saga: { next, reject } }) {
    const clientCorrelationId = yield select(transactionSelectors.clientCorrelationId)
    const kioskName = yield select(transactionSelectors.kioskName)
    const token = yield select(state => state.auth.accessToken)
    try {
      yield fetch(`https://${process.env.REACT_APP_CAP_ONE_API_HOST}/logout`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${token}`,
          token,
          Accept: 'application/json;v=2',
          'Content-Type': 'application/json;v=2'
        }
      }).then(handleApiErrors)
      yield put(next())
      yield put(errorActions.clearErrors())
    } catch (e) {
      log(kioskName, clientCorrelationId, e.toString(), token)
      if (e.statusCode >= 400 && e.statusCode <= 499) {
        yield put(errorActions.setGeneralError(e))
        yield put(reject(e))
      } else {
        yield put(errorActions.setGeneralError(e))
      }
    }
  }
}

export const actions = {
  setOauthAuthorizationCode: authorizationCode => ({
    type: actions.setOauthAuthorizationCode,
    payload: authorizationCode,
    reducer: (s, authorizationCode) => ({ ...s, authorizationCode })
  }),
  setProfileReferenceId: profileReferenceId => ({
    type: actions.setProfileReferenceId,
    payload: profileReferenceId,
    reducer: (s, profileReferenceId) => {
      return { ...s, profileReferenceId }
    }
  }),
  loginThenGotoTokenComponent: navigate => ({
    type: actions.loginThenGotoTokenComponent,
    saga: {
      init: sagas.loginThenGotoTokenComponent,
      next: actions.loginOk,
      reject: actions.loginKo
    },
    payload: navigate,
    reducer: s => s
  }),
  loginOk: profileReferenceId => ({
    type: actions.loginOk,
    payload: profileReferenceId,
    reducer: (s, profileReferenceId) => ({ ...s, profileReferenceId })
  }),
  loginKo: e => ({
    type: actions.loginKo,
    payload: e,
    reducer: (s, e) => ({ ...s, errors: { ...s.errors, ...e } })
  }),
  logout: () => ({
    type: actions.logout,
    saga: {
      init: sagas.logout,
      next: actions.logoutOk,
      reject: actions.logoutKo
    }
  }),
  logoutOk: () => ({
    type: actions.logoutOk
  }),
  logoutKo: e => ({
    type: actions.logoutKo,
    payload: e,
    reducer: (s, e) => ({ ...s, errors: { ...s.errors, ...e } })
  }),
  fetchOauthAccessToken: () => ({
    type: actions.fetchOauthAccessToken,
    saga: {
      init: sagas.fetchOauthAccessToken,
      next: actions.fetchOauthAccessTokenOk,
      reject: actions.fetchOauthAccessTokenKo
    }
  }),
  fetchOauthAccessTokenOk: (accessToken, refreshToken) => ({
    type: actions.fetchOauthAccessTokenOk,
    payload: { accessToken, refreshToken },
    reducer: (s, payload) => ({ ...s, ...payload })
  }),
  fetchOauthAccessTokenKo: e => ({
    type: actions.fetchOauthAccessTokenKo,
    payload: e,
    reducer: (s, e) => ({ ...s, errors: { ...s.errors, ...e } })
  })
}

export const sagaWatchers = {
  *fetchOauthAccessTokenWatcher() {
    yield takeLatest(
      actionMatcher(actions.fetchOauthAccessToken),
      actions.fetchOauthAccessToken().saga.init
    )
  },
  *loginWatcher() {
    yield takeLatest(
      actionMatcher(actions.loginThenGotoTokenComponent),
      actions.loginThenGotoTokenComponent().saga.init
    )
  },
  *logoutWatcher() {
    yield takeLatest(actionMatcher(actions.logout), actions.logout().saga.init)
  }
}

export const subscriptions = {
  subscribeToOauthAuthorizationCode: store =>
    observeStore(
      store,
      s => s.auth.authorizationCode,
      (prev, curr) => {
        // console.log(`OAuth changed: prev=${prev}, curr=${curr}`)
        if (prev !== curr && curr) store.dispatch(actions.fetchOauthAccessToken())
      }
    )
}

const defaultAuthReducer = defaultReducer(actions)
export const reducer = (s = initialState, a) => {
  const s2 = defaultAuthReducer(s, a)
  switch (a.type) {
    case errorActions.clearErrors:
      return { ...s2, errors: initialState.errors }
    default:
      return s2
  }
}
