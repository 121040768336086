import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import GrvIcon from '../grv-icon'

const BackButton = props => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { backTargetRoute } = props

  return (
    <div
      id='back-button'
      className='action-bar-item'
      role='button'
      tabIndex={0}
      onClick={() => navigate(backTargetRoute.path)}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          navigate(backTargetRoute.path)
        }
      }}
    >
      <GrvIcon className='action-bar-item-icon' iconClass='ui-lined-arrow-left-1-24' />
      {props.backText(t)}
    </div>
  )
}

BackButton.propTypes = {
  backTargetRoute: PropTypes.shape({
    path: PropTypes.string
  }),
  backText: PropTypes.func
}

export default BackButton
