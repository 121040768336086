import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { selectors as accountSelectors } from '@modules/account'
import { actions as checkActions, selectors as checkSelectors } from '@modules/check'
import { selectors as transactionSelector } from '@modules/transaction'
import { useIsContentOverflowing } from '@hooks/use-is-content-overflowing'
import { useActions } from '@hooks/use-actions'
import { isCheckInfoValid } from '@util/check-validation'
import FieldError from '@components/field-error'
import Loader from '@components/loader'
import { formatAmountToCurrency, formatCentsToDollarsAndCents } from '@util/currency'

const Check = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const acct = useSelector(accountSelectors.account)
  const payee = useSelector(checkSelectors.payee)
  const amount = useSelector(state => state.check.amount)
  const memo = useSelector(checkSelectors.memo)
  const customerCheckCentsLimit = useSelector(transactionSelector.customerCheckCentsLimit)
  const customerPreviousCheckAmountSum = useSelector(
    accountSelectors.customerPreviousCheckAmountSum
  )
  const [isValidCheck, setIsValidCheck] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({
    payee: '',
    amount: '',
    memo: '',
    touched: {
      payee: false,
      amount: false,
      memo: false
    }
  })
  const [setPayee, setAmount, setMemo, offerCheckDetailsThenGotoConfirmation] = useActions([
    checkActions.setPayee,
    checkActions.setAmount,
    checkActions.setMemo,
    checkActions.offerCheckDetailsThenGotoConfirmation
  ])

  useEffect(() => {
    const validatedInfo = isCheckInfoValid({
      payee,
      amount,
      memo,
      balance: acct.availableBalance,
      customerPreviousCheckAmountSum,
      customerCheckCentsLimit
    })

    setErrors({
      ...errors,
      payee: validatedInfo.errors.payee,
      amount: validatedInfo.errors.amount,
      memo: validatedInfo.errors.memo
    })
    setIsValidCheck(validatedInfo.valid)
  }, [payee, amount, memo])

  const setErrorTouched = field => {
    setErrors({ ...errors, touched: { ...errors.touched, [field]: true } })
  }

  const bodyElement = document.querySelector('body')
  const [contentContainerRef, btnRef] = useIsContentOverflowing(bodyElement, 2, [
    [0, e => e.offsetTop]
  ])

  return (
    <>
      {isLoading && (
        <div style={{ zIndex: 999, position: 'fixed' }}>
          <Loader style={{ backgroundColor: 'rgba(169, 169, 169, 0.43)', cursor: 'default' }} />
        </div>
      )}
      <form>
        <div ref={contentContainerRef} className='content-container'>
          <h2 id='check-details-header' className='content-title'>
            {t('check.contentTitle')}
          </h2>

          <div className='normal-margin-bottom'>
            <div style={{ fontSize: 16 }} className='grv-text__body--medium'>
              {acct.accountName}
            </div>
            <div className='grv-text__body--tiny'>
              {formatAmountToCurrency(acct.availableBalance)}{' '}
              {t('accountSelection.availableBalance')}
            </div>
          </div>

          <div className='grv-form-field'>
            <label
              htmlFor='payee'
              className='grv-textfield__label check-form-label'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              {t('check.payeeLabel')}
              <span className='field-length-counter'>{payee ? payee.length : 0}/48</span>
            </label>
            <input
              type='text'
              name='payee'
              id='payee-input'
              placeholder={t('check.payeePlaceholder')}
              spellCheck='false'
              className='grv-textfield__input'
              value={payee}
              onChange={e => setPayee(e.target.value)}
              onKeyUp={e => setErrorTouched(e.target.name)}
              onBlur={e => setErrorTouched(e.target.name)}
            />
            <span className='grv-textfield__helper check-form-helper'>
              {t('check.payeeHelper')}
            </span>
            {errors.touched.payee && <FieldError errors={errors} name='payee' />}
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ width: '100%' }} className='grv-form-field'>
              <label htmlFor='amount' className='grv-textfield__label check-form-label'>
                {t('check.amountLabel')}
              </label>
              <input
                type='text'
                name='amount'
                placeholder={t('check.amountPlaceholder')}
                id='amount-input'
                className='grv-textfield__input'
                value={amount}
                onChange={e => setAmount(e.target.value)}
                onKeyUp={e => setErrorTouched(e.target.name)}
                onBlur={e => setErrorTouched(e.target.name)}
              />
              <span className='grv-textfield__helper check-form-helper'>
                {`${t('check.amountHelper1')} ${formatAmountToCurrency(
                  formatCentsToDollarsAndCents(customerCheckCentsLimit)
                )} ${t('check.amountHelper2')}`}
              </span>
              {errors.touched.amount && <FieldError errors={errors} name='amount' />}
            </span>
          </div>

          <div className='grv-form-field'>
            <label
              htmlFor='memo'
              className='grv-textfield__label check-form-label'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <span>{t('check.memoLabel')}</span>
              <span className='field-length-counter'>{memo ? memo.length : 0}/40</span>
            </label>
            <input
              type='text'
              name='memo'
              id='memo-input'
              placeholder={t('check.memoPlaceholder')}
              className='grv-textfield__input'
              value={memo}
              onChange={e => setMemo(e.target.value)}
              onKeyUp={e => setErrorTouched(e.target.name)}
              onBlur={e => setErrorTouched(e.target.name)}
            />
            <span className='grv-textfield__helper check-form-helper'>{t('check.memoHelper')}</span>
            {errors.touched.memo && <FieldError errors={errors} name='memo' />}
          </div>

          <button
            id='review-check-button'
            type='button'
            ref={btnRef}
            className='progress-button'
            disabled={!isValidCheck}
            onClick={e => {
              e.preventDefault()
              setIsLoading(true)
              offerCheckDetailsThenGotoConfirmation(navigate)
            }}
          >
            {t('check.done')}
          </button>
        </div>
      </form>
    </>
  )
}

export default Check
