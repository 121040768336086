import React from 'react'
import _ from 'lodash'

import Landing from '@pages/landing'
import Login from '@pages/login'
import AccountSelection from '@pages/account-selection'
import Check from '@pages/check'
import CheckConfirmation from '@pages/check-confirmation'
import QrDemo from '@pages/qr-demo'
import Qr from '@pages/qr'
import PollPrintStatus from '@pages/poll-print-status'
import Error from '@pages/error'
import SessionTimeout from '@pages/session-timeout'
import EaseRedirection from '@pages/ease-redirection'

import Token from '@components/token'

export const routes = {
  landing: { path: '/', element: <Landing /> },
  login: { path: '/login', element: <Login /> },
  token: { path: '/oauth2/token', element: <Token /> },
  accountSelection: {
    path: '/account-selection',
    element: <AccountSelection />,
    protected: true
  },
  check: {
    path: '/check',
    element: <Check />,
    protected: true
  },
  checkConfirmation: {
    path: '/check-confirmation',
    element: <CheckConfirmation />,
    protected: true
  },
  error: {
    path: '/error',
    element: <Error />,
    protected: false
  },
  qrDemo: {
    path: '/qr-demo',
    element: <QrDemo />,
    protected: true
  },
  qr: {
    path: '/qr',
    element: <Qr />,
    protected: true
  },
  pollPrintStatus: {
    path: '/poll-print-status',
    element: <PollPrintStatus />,
    protected: true
  },
  sessionTimeout: {
    path: '/session-timeout',
    element: <SessionTimeout />,
    protected: false
  },
  easeRedirection: {
    path: '/ease-redirection',
    element: <EaseRedirection />,
    protected: true
  }
}

export const wizards = {
  buyCashiersCheck: {
    wizardId: 'buyCashiersCheck',
    description: "This wizard leads the customer through buying a cashier's check.",
    routes: [
      routes.landing,
      routes.accountSelection,
      routes.check,
      routes.checkConfirmation,
      routes.easeRedirection,
      routes.error,
      routes.qrDemo,
      routes.qr,
      routes.pollPrintStatus
    ],
    canGoBack: [
      {
        route: routes.check,
        backTargetRoute: routes.accountSelection,
        backText: t => t('check.backText')
      },
      {
        route: routes.checkConfirmation,
        backTargetRoute: routes.check,
        backText: t => t('checkConfirmation.backText')
      }
    ],
    canCancelWizard: [
      routes.accountSelection,
      routes.check,
      routes.checkConfirmation,
      routes.easeRedirection,
      routes.qrDemo,
      routes.qr
    ]
  }
}

export const getRouteByPath = path => {
  /* eslint-disable-next-line no-unused-vars */
  const route = Object.entries(routes).find(([k, v]) => v.path === path)
  return route === undefined ? undefined : route[1]
}

export const routeCanGoBack = (wizard, routeUnderQuestion) => {
  const routeBackObj = wizard.canGoBack.find(routeBackObj =>
    _.isEqual(routeBackObj.route, routeUnderQuestion)
  )
  return [routeBackObj, routeBackObj !== undefined]
}

export const routeCanCancel = (wizard, routeUnderQuestion) => {
  const route = wizard.canCancelWizard.find(route => _.isEqual(route, routeUnderQuestion))
  return [route, route !== undefined]
}
