import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import qrCodeLib from 'qrcode'
import { useTranslation } from 'react-i18next'

import { selectors as accountSelectors } from '@modules/account'
import { selectors as authSelectors } from '@modules/auth'
import { selectors as checkSelectors } from '@modules/check'
import { selectors as transactionSelectors } from '@modules/transaction'
import { useInterval } from '@hooks/use-interval'
import { useIsContentOverflowing } from '@hooks/use-is-content-overflowing'
import { utoa } from '@util/utoa'
import Loader from '@components/loader'
import { handleErrorDispatch } from '@util/error'
import { getKioskAndTransactionStatus, getTransactionDetails, log } from '@api/ccc-api-calls'

const Qr = () => {
  const { t } = useTranslation()
  const kioskName = useSelector(transactionSelectors.kioskName)
  const clientCorrelationId = useSelector(transactionSelectors.clientCorrelationId)
  const account = useSelector(accountSelectors.account)
  const payee = useSelector(state => state.check.payee)
  const displayAmount = useSelector(checkSelectors.displayAmount)
  const [qrCode, setQrCode] = useState('')
  const [isQrCodeScanned, setQrCodeScanned] = useState(false)
  const bodyElement = document.querySelector('body')
  const accessToken = useSelector(authSelectors.accessToken)
  const profileReferenceId = useSelector(authSelectors.profileReferenceId)
  /* eslint-disable-next-line no-unused-vars */
  const [isOverflowing, contentContainerRef, detailsContainerRef] = useIsContentOverflowing(
    bodyElement,
    2,
    [[0, e => e.offsetTop]]
  )

  useEffect(() => {
    async function updateQRCode() {
      try {
        const response = await getTransactionDetails(
          kioskName,
          clientCorrelationId,
          accessToken,
          profileReferenceId
        )
        const encodedTransactionId = await qrCodeLib.toDataURL(
          utoa(
            JSON.stringify({
              clientCorrelationId: response?.newUUID || clientCorrelationId
            })
          )
        )
        setQrCode(encodedTransactionId)
      } catch (err) {
        log(kioskName, clientCorrelationId, err.toString(), accessToken)
        handleErrorDispatch(err.name, err.message)
      }
    }
    updateQRCode()
  }, [clientCorrelationId, kioskName])

  async function pollKioskStatus() {
    try {
      const status = await getKioskAndTransactionStatus(
        kioskName,
        clientCorrelationId,
        accessToken,
        profileReferenceId
      )

      switch (status.kioskStatus) {
        case 'PRINTING':
          setQrCodeScanned(true)
          break
        case 'OUT_OF_SERVICE':
        case 'UNPROVISIONED':
          handleErrorDispatch(status.kioskStatus, null)
          break
        default:
      }
    } catch (err) {
      log(kioskName, clientCorrelationId, err.toString(), accessToken)
      handleErrorDispatch(err.name, err.message)
    }
  }

  const displayTransactionDetails = (text, value) => {
    return (
      <div className='qr-transaction-details-row'>
        <span style={{ fontSize: 16 }} className='grv-text__heading--medium'>
          {text}
        </span>
        <span className='grv-text__body--medium transaction-details-value'>{value}</span>
      </div>
    )
  }

  useInterval(() => {
    // run poller that will redirect the user after we get a status that the qrCode has been scanned on the aeon
    pollKioskStatus()
  }, 1000)

  if (!qrCode) return <Loader />

  return (
    <>
      <div
        ref={contentContainerRef}
        style={{ marginBottom: '16px' }}
        className='content-container qr-content'
      >
        <div>
          <h2 style={{ textAlign: 'center' }} className='content-title'>
            {t('qr.header')}
          </h2>

          <div className='qr-image-container'>
            <img className='qr-image' src={qrCode} alt="QR code for Cashier's Check" />
          </div>

          {isQrCodeScanned ? <Navigate to='/poll-print-status' replace /> : null}

          <div
            id='qr-disclaimer'
            style={{ textAlign: 'center' }}
            className='grv-text__body--normal'
          >
            {t('qr.subheader')}
          </div>
        </div>
        <div ref={detailsContainerRef} className='qr-check-details'>
          {displayTransactionDetails('Payable to', payee)}
          {displayTransactionDetails('Amount', displayAmount)}
          {displayTransactionDetails('Account', account.accountName)}
        </div>
      </div>
    </>
  )
}

export default Qr
