import { routes } from '../routes'

const SESSION_TIMEOUT_MS = process.env.REACT_APP_SESSION_TIMEOUT_MS

let navigate
let sessionTimeout

export const startSessionTimer = navigateProp => {
  if (!sessionTimeout) {
    const routeName = routes.sessionTimeout.path
    navigate = navigateProp

    sessionTimeout = setTimeout(() => {
      navigate(routeName, { replace: true }) // redirect to /session-timeout
    }, SESSION_TIMEOUT_MS)
  }
}

export const clearSessionTimeout = () => {
  clearTimeout(sessionTimeout)
}
